import React from 'react';
import './App.css';
import Chatbot from './Chatbot';  // Import the Chatbot component

function App() {
  return (
    <div className="App">
      <h1>FinChat</h1>
      <Chatbot />  {/* Use the Chatbot component */}
    </div>
  );
}

export default App;
