import React, { useState, useEffect, useRef } from 'react';
import './Chatbot.css';

const Chatbot = () => {
  const [userInput, setUserInput] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isTyping, setIsTyping] = useState(false); // State to manage typing indicator
  const chatEndRef = useRef(null); // Ref for auto-scrolling

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (userInput.trim() === '') return;

    // Create the user message object
    const newMessage = { sender: 'User', message: userInput };

    // Add user message to chat history
    setChatHistory((prev) => [...prev, newMessage]);

    // Clear the input field immediately
    setUserInput('');

    // Show typing indicator
    setIsTyping(true);

    try {
      const response = await fetch('http://202.43.249.216:8080/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: userInput }),
      });

      const data = await response.json();

      // Create the bot message object
      const botMessage = { sender: 'Bot', message: data.response };

      // Add bot message to chat history
      setChatHistory((prev) => [...prev, botMessage]);

      // Hide typing indicator
      setIsTyping(false);
    } catch (error) {
      console.error('Error:', error);
      // Ensure typing indicator is hidden even in case of error
      setIsTyping(false);
    }
  };

  // Auto-scroll to bottom whenever chatHistory changes
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  return (
    <div className="chatbot-container">
      <div className="chat-window">
        <div className="chat-history">
          {chatHistory.map((chat, index) => (
            <div key={index} className={`chat-message ${chat.sender}`}>
              {chat.message}
            </div>
          ))}
          {isTyping && (
            <div className="chat-message Bot typing">typing...</div>
          )}
          <div ref={chatEndRef} /> {/* Empty div to scroll to */}
        </div>
        <form onSubmit={handleSendMessage} className="chat-input">
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder="Ask about financial data..."
          />
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
};

export default Chatbot;
